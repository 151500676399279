const MRI_PUTRA_ACUTE_STROKE = `Clinical Indication;

Code:  Red/Yellow/Green
NIHSS :  /42
MRS: /5
Known history of HPT/Diabetes Mellitus/ICAD/previous infarct/AF/IHD/others
Clinical signs & symptoms, relevant history [if applicable]
IVT: Yes/No (if yes what drug?)

MRI Putra Acute Stroke Protocol

MRI sequence:


 Findings;

Acute Lesion [yes/no]
If yes;
-	Haemorrhagic/ischemic/mixed
-	Single/multiple
-	Locations [description]
-	Estimated age [hyperacute/acute/subacute/old] - signal on ADC + DWI + FLAIR
-	Other relevant findings [can write whatever]

Chronic Lesion [yes/no]
If yes;
-	Haemorrhagic/ischemic/mixed
-	Single/multiple
-	Locations [description]
-	Estimated age [hyperacute/acute/subacute/old] - signal on ADC + DWI + FLAIR
-	Other relevant findings [can write whatever]

Vessel occlusion [yes/no]
If yes;
- Single/multiple
- LVO/MeVO/DVO : location?
- Other features [stagnant signs/PVS-prominent vein signs/FLAIR hyperintense vessel signs]

Other vascular anomalies [yes/no]
If yes;
-	ICAD : [yes/no] ,[single/multiple/diffuse], [locations/segment involvement], [wall enhancement]
-	Carotid: [occluded/stenosis/normal], if stenosis [location, estimated grade - <50%, 50-75%, 75-90%, critical >90%]

Other findings
-	Haemorrhagic/ SOL/Others

IMPRESSION:


 Recommendation of treatment
- [Thrombolyse/Thrombectomy/Both/Medical Therapy/not decided]

Recommended subsequent imaging:
- further imaging to rule out other potential abnormality [ie in stroke mimics, other suspicious abnormality to re-asses clinically]
- follow-up imaging [suggest day 5], 
- Inpatient cerebral DSA [mod-severe ICAD, CAS, other suspicious lesion – avm, aneurysm, fistulae], other possible vasculopathy [CAA, vasculitis, microhaemorrhages HPT/DM]

*Disclaimer this imaging done for acute stroke, the protocol not optimised to evaluate other possible clinical problems. Neuroimaging with appropriate protocols maybe necessary, however unable to be accommodated during this examination due to time contraints and urgency to establish treatment for acute stroke which is the main focus of this examination.
`

export default MRI_PUTRA_ACUTE_STROKE;
